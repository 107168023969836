<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div class="page">
    <!-- Header -->
    <Header :shareBtn="true" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <div class="page-title-clear"></div>

    <div class="page-content">
      <div class="card card-style">
        <div class="content">
          <h1 class="mb-0 font-600 color-highlight">Kebijakan Privasi</h1>
          <ul>
            <li>
              Informasi yang dimasukkan untuk resume akan ditampilkan
              sebagaimana adanya, dan tidak di-edit oleh PasJob.com. Untuk iklan
              lowongan kerja melalui tahap verifikasi terlebih dahulu oleh tim
              PasJob.com Harap kaji informasi yang dibutuhkan dan pastikan
              informasi mana yang dapat dilihat oleh pengunjung PasJob.com.
            </li>
            <li>
              Ringkasan Resume hanya dapat dilihat oleh perusahaan yang memiliki
              iklan lowongan yang telah diverifikasi (email dan no telp tidak
              ditampilkan).
            </li>
            <li>
              Bila Kandidat melamar secara online, Resume lengkap (termasuk
              email dan no telp) akan ditampilkan ke perusahaan yang dilamar.
            </li>
            <li>
              Resume lengkap Anda (termasuk email dan no telp) akan ditampilkan
              ke perusahaan yang menawarkan pekerjaan ke anda dengan disertai
              email pemberitahuan ke kandidat. Sehingga kandidat tahu perusahaan
              mana saja yang mengakses resume lengkapnya.
            </li>
            <li>
              Kami sarankan untuk merahasiakan password Anda. Kami tidak akan
              pernah menanyakan password Anda melalui telpon ataupun email dan
              tidak akan memberikan password Anda ke pihak ketiga.
            </li>
            <li>
              Account Anda di PasJob.com dilindungi dengan password. Artinya,
              hanya Anda yang mempunyai akses ke account Anda dan hanya Anda
              yang bisa mengubah segala informasi yang dimasukkan melalui
              account Anda.
            </li>
            <li>
              Setelah Anda selesai menggunakan PasJob.com, jangan lupa untuk
              logout. Hal ini untuk memastikan bahwa tidak ada pihak lain yang
              mengakses account Anda, khususnya bila komputer digunakan
              bersama-sama atau bila Anda menggunakan komputer di tempat umum
              seperti perpustakaan atau kafe/warung internet.
            </li>
            <li>
              Cookies adalah serangkaian informasi yang dipindahkan dari situs
              ke hard disk komputer Anda untuk penyimpanan data. Cookies
              memberikan keuntungan bagi situs dalam beberapa hal dengan
              menyimpan informasi mengenai preferensi-preferensi Anda ketika
              mengunjungi sebuah situs. Banyak situs terkemuka yang menggunakan
              cookies untuk memberikan keistimewaan-keistimewaan yang
              menguntungkan bagi pengguna situs mereka. Cookies dapat mengenali
              komputer Anda, namun tidak dapat mengenali identitas Anda.
              Kebanyakan browsers dapat menerima cookies, dengan catatan browser
              Anda telah diset terlebih dahulu. Apabila browser Anda tidak dapat
              menerima cookies, maka Anda sama sekali tidak akan dapat mengakses
              ke situs kami.
            </li>
            <li>
              Apabila suatu saat nanti kami harus mengubah Polis Kerahasiaan
              kami, maka kami akan mencantumkannya di sini agar para pengguna
              dapat mengetahui informasi apa saja yang kami kumpulkan dan
              bagaimana kami menggunakan informasi tersebut. Data pribadi Anda
              akan digunakan sesuai dengan polis kerahasiaan kami. Apabila,
              sewaktu-waktu Anda ingin mengajukan pertanyaan ataupun memberikan
              komentar tentang Polis Kerahasiaan kami, maka Anda dapat
              menghubungi kami lewat email info@PasJob.com atau menghubungi
              telepon 082114553331 dan langsung berbicara dengan salah satu staf
              kami.
            </li>
            <li>
              Informasi yang dikirim tetap bersifat pribadi dan hanya digunakan
              oleh perusahaan sebagai bahan evaluasi dan digunakan oleh Pelamar
              untuk melamar secara online. Informasi tersebut tidak akan
              diberikan ke pihak ketiga.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { init_template } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    Header,
    Footer,
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
};
</script>
